import './src/theme/destyle.css'
import './src/theme/global.css'

import { ThemeProvider } from './src/theme'

const transitionDelay = 200

export const wrapPageElement = ({ element }) => {
  return <ThemeProvider>{element}</ThemeProvider>
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  return false
}
